import React from 'react'
import { FaInstagram, FaLink, FaWhatsapp } from 'react-icons/fa'
import Logo from '../images/AME_transparent.png'

const Footer = () => {
  return (
    <div className="flex flex-col text-black-100 mt-5 text-left">
            <div className="flex max-md:flex-col flex-wrap sm:px-16 px-6 py-10 footer-background">
                <div className="flex flex-col justify-start items-start gap-6 mr-24">
                    <img
                        src={Logo}
                        alt="logo"
                        width={80}
                        height={90}
                    />
                </div>
                <div className="ml-24">
                    <p className="text-black font-bold text-xl mb-4">
                        HUBUNGI KAMI
                    </p>
                    <div className="flex gap-x-4 mb-2">
                        <a href="https://www.instagram.com/yayasan_aku_melihat_engkau/" className="text-slate-600 text-2xl" >
                            <FaInstagram />
                        </a>
                        <a href="https://www.stichtingakumelihatengkau.org/" className="text-slate-600 text-2xl" >
                            <FaLink />
                        </a>
                        <a href="https://wa.me/+6281231661282" className="text-slate-600 text-2xl" >
                            <FaWhatsapp />
                        </a>
                    </div>
                    <p className="text-black mb-2">BCA • 800-5146603
                        <br />
                        a.n. Yay Kesejahteraan Anak AME</p>
                    <p className="text-black mb-2">Jl. Menteng II Gang Pembangunan No.3 , Kelurahan Binjai, Kecamatan Medan Denai, Medan, Sumatera Utara, 20226</p>
                    <p className="font-bold">
                        <span className="text-slate-600">
                            YAYASAN KESEJAHTERAAN ANAK
                        </span>
                        &nbsp;
                        <span className="text-black">AKU MELIHAT ENGKAU</span>
                    </p>
                </div>
            </div>
            <div className="text-center border-t border-gray-100 sm:px-16 px-6 py-5 copyright-background">
                <p className="text-black">@2023 mefolio. All Rights Reserved</p>
            </div>
        </div>
    )
}

export default Footer