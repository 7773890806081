import React from 'react'
import Profile from '../images/profile.jpeg'
import { motion } from 'framer-motion';
import { Card, CardContent } from '@mui/material';

const Content = () => {

    const fadeAnimationVariants = {
        initial: {
            opacity: 0,
            y: 100
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.2
            }
        },
    };

    return (
        <div id='content' className=''>
            <p className="text-black font-bold text-2xl pt-10">Visi dan Misi</p>
            <div className="content_card_2 max-w-[1240px] text-black p-10 mx-auto background-white my-10">
                <Card sx={{ borderRadius: '1.5rem' }}>
                    <CardContent>
                        <motion.div
                            variants={fadeAnimationVariants}
                            initial="initial"
                            whileInView="animate"
                            className="px-10 flex flex-row justify-between"
                        >
                            <div className="flex flex-col gap-y-7 text-left py-10 pr-8" >
                                <p className='font-bold text-4xl'>VISI</p>
                                <p className='lg:text-xl'>Yayasan ini mempunyai tujuan untuk dapat memberikan tempat tinggal dan pengajaran bagi anak-anak yang tidak mampu pada usia 0 - 18 tahun di Sumatera Utara, Indonesia.</p>
                                <p className='font-bold text-4xl'>MISI</p>
                                <div className="lg:text-xl text-left">
                                    <p className="pb-2">1. Memberikan tempat tinggal dan memenuhi kebutuhan sehari - hari</p>
                                    <p className="pb-2">2. Memberikan bantuan pendidikan dan menyekolahkan mereka</p>
                                    <p className="pb-2">3. Mengajarkan nilai dan norma kehidupan</p>
                                    <p className="pb-2">4. Memberikan bimbingan pribadi agar mereka dapat berfungsi secara mandiri</p>
                                    <p className="pb-2">5. Membentuk iman dan karakter bagi anak - anak</p>
                                </div>
                            </div>
                            <div className="hidden lg:block py-10">
                                <img src={Profile} alt="profile"
                            width={800}
                            height={1000}className='rounded-md'/>
                            </div>
                        </motion.div>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default Content