import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Box, Button, useMediaQuery } from "@mui/material";
import Logo from '../images/AME_transparent.png';
import { Close, Menu as MenuIcon } from "@mui/icons-material";
import { Link } from "react-scroll";

const Navbar = () => {

  const [color, setColor] = useState('transparent')
  const [textColor, setTextColor] = useState('white')

  const isLargeScreen = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 90) {
        setColor('#F0F0D0')
        setTextColor('#000000')
      } else {
        setColor('transparent')
        setTextColor('#FFFFFF')
      }
    }
    window.addEventListener('scroll', changeColor);
  }, []);

  let [openMenu, setOpenMenu] = useState(false);

  return (
    <AppBar position="fixed" sx={{ backgroundColor: isLargeScreen ? `${color}` : '#F0F0D0' }} elevation={0}>
      <Toolbar className="flex lg:flex-row flex-col">
        <Box sx={{ marginRight: 2, marginLeft: 2 }}>
          <Link to="hero" spy={true} smooth={true} offset={-125} duration={500}>
          <img src={Logo} alt="logo_aoa" width={80} height={90} />
          </Link>
        </Box>
        <div onClick={() => setOpenMenu(!openMenu)} className="text-black text-5xl absolute right-8 top-6 cursor-pointer lg:hidden">
          {openMenu ? <Close name="close" /> : <MenuIcon name="menu" />}
        </div>
        {/* open extended navbar start */}
        {isLargeScreen ? (
          <div className="lg:justify-end lg:flex lg:flex-row lg:grow w-full lg:w-auto">
            <Box sx={{ display: { xs: 'block', lg: 'flex' } }}>
              <Button
                sx={{ my: 2, color: isLargeScreen ? `${textColor}` : `#000000`, display: 'block', textTransform: 'none', mx: 1 }}
              >
                <Link to='content' spy={true} smooth={true} offset={-125} duration={500} className="mx-4 text-xl">Visi dan Misi</Link>
              </Button>
              <Button
                sx={{ my: 2, color: isLargeScreen ? `${textColor}` : `#000000`, display: 'block', textTransform: 'none', mx: 1 }}
              >
                <Link to='cerita1' spy={true} smooth={true} offset={-125} duration={500} className="mx-4 text-xl">Cerita</Link>
              </Button>
              {/* <Button
                sx={{ my: 2, color: isLargeScreen ? `${textColor}` : `#000000`, display: 'block', textTransform: 'none', mx: 1 }}
              >
                <Link to='gallery' spy={true} smooth={true} offset={-125} duration={500} className="mx-4 text-xl">Foto Kegiatan</Link>
              </Button> */}
            </Box>
          </div>
        ) : (
          openMenu && (
            <div className="extended_navbar">
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button
                  sx={{ my: 2, color: isLargeScreen ? `${textColor}` : `#000000`, display: 'block', textTransform: 'none', mx: 1 }}
                >
                  <Link to='content' spy={true} smooth={true} offset={-125} duration={500} className="mx-4 text-xl">Visi dan Misi</Link>
                </Button>
                <Button
                  sx={{ my: 2, color: isLargeScreen ? `${textColor}` : `#000000`, display: 'block', textTransform: 'none', mx: 1 }}
                >
                  <Link to='cerita1' spy={true} smooth={true} offset={-125} duration={500} className="mx-4 text-xl">Cerita</Link>
                </Button>
                {/* <Button
                  sx={{ my: 2, color: isLargeScreen ? `${textColor}` : `#000000`, display: 'block', textTransform: 'none', mx: 1 }}
                >
                  <Link to='gallery' spy={true} smooth={true} offset={-125} duration={500} className="mx-4 text-xl">Foto Kegiatan</Link>
                </Button> */}
              </Box>
            </div>
          )
        )}
        {/* open extended navbar end  */}
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;