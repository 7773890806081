import './App.css';
import Content from './components/Content';
import Footer from './components/Footer';
// import Gallery from './components/Gallery';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import ResponsiveGallery from './components/ResponsiveGallery';
// import Slider from './components/Slider';
// import { SliderData } from './components/SliderData';

function App() {
  return (
    <div className="App" style={{ backgroundColor: '#F0F0D0' }}>
      <Navbar />
      <div className="hero-container">
        <Hero />
      </div>
        <Content />
        <ResponsiveGallery/>
      {/* <div className="slider-container">
        <Slider slides={SliderData} />
      </div>
      <div className="">
        <Gallery />
      </div> */}
      <Footer />
    </div>
  );
}

export default App;
