export const SliderData = [
    {
        image: '/kegiatan/bangunan.jpg',
        description: 'Bangunan yayasan kesejahteraan anak Aku Melihat Engkau'
    },
    {
        image: '/kegiatan/kegiatan_makan_bersama_anak_asuh.jpg',
        description: 'Kegiatan makan bersama anak asuh'
    },
    {
        image: '/kegiatan/kegiatan_belajar_anak.jpg',
        description: 'Kegiatan belajar anak',
    },
    {
        image: '/kegiatan/tiga_1.jpg',
        description: 'Kegiatan Ibadah Anak setiap Minggu (Kegiatan ini dilaksanakan dengan mengundang anak – anak lain yang berada di lingkungan sekitar rumah)',
    },
    {
        image: '/kegiatan/tiga_2.jpg',
        description: 'Kegiatan Ibadah Anak setiap Minggu (Kegiatan ini dilaksanakan dengan mengundang anak – anak lain yang berada di lingkungan sekitar rumah)',
    },
    {
        image: '/kegiatan/tiga_3.jpg',
        description: 'Kegiatan Ibadah Anak setiap Minggu (Kegiatan ini dilaksanakan dengan mengundang anak – anak lain yang berada di lingkungan sekitar rumah)',
    },
    {
        image: '/kegiatan/tiga_4.jpg',
        description: 'Kegiatan Ibadah Anak setiap Minggu (Kegiatan ini dilaksanakan dengan mengundang anak – anak lain yang berada di lingkungan sekitar rumah)',
    },
    {
        image: '/kegiatan/tiga_5.jpg',
        description: 'Kegiatan Ibadah Anak setiap Minggu (Kegiatan ini dilaksanakan dengan mengundang anak – anak lain yang berada di lingkungan sekitar rumah)',
    },
    {
        image: '/kegiatan/tiga_6.jpg',
        description: 'Kegiatan Ibadah Anak setiap Minggu (Kegiatan ini dilaksanakan dengan mengundang anak – anak lain yang berada di lingkungan sekitar rumah)',
    },
    {
        image: '/kegiatan/paskah.jpg',
        description: 'Kegiatan yang dilakukan pada hari besar keagamaan Kristen ( foto ini diambil saat kegiatan paskah)'
    },
    {
        image: '/kegiatan/makan_1.jpg',
        description: 'Kegiatan makan bersama dengan anak-anak sekitar lingkungan'
    },
    {
        image: '/kegiatan/makan_2.jpg',
        description: 'Kegiatan makan bersama dengan anak-anak sekitar lingkungan'
    },
    {
        image: '/kegiatan/makan_3.jpg',
        description: 'Kegiatan makan bersama dengan anak-anak sekitar lingkungan'
    },
    {
        image: '/kegiatan/makan_4.jpg',
        description: 'Kegiatan makan bersama dengan anak-anak sekitar lingkungan'
    },
    {
        image: '/kegiatan/sosial1.jpg',
        description: 'Kegiatan sosial keluar Yayasan dengan memberikan makanan bagi gelandangan dan ODGJ'
    },
    {
        image: '/kegiatan/sosial2.jpg',
        description: 'Kegiatan sosial keluar Yayasan dengan memberikan makanan bagi gelandangan dan ODGJ'
    },
    {
        image: '/kegiatan/sosial3.jpg',
        description: 'Kegiatan sosial keluar Yayasan dengan memberikan makanan bagi gelandangan dan ODGJ'
    },
    {
        image: '/kegiatan/sosial4.jpg',
        description: 'Kegiatan sosial keluar Yayasan dengan memberikan makanan bagi gelandangan dan ODGJ'
    },
    {
        image: '/kegiatan/sosial5.jpg',
        description: 'Kegiatan sosial keluar Yayasan dengan memberikan makanan bagi gelandangan dan ODGJ'
    },
    {
        image: '/kegiatan/sosial6.jpg',
        description: 'Kegiatan sosial keluar Yayasan dengan memberikan makanan bagi gelandangan dan ODGJ'
    },
    {
        image: '/kegiatan/sosial7.jpg',
        description: 'Kegiatan sosial keluar Yayasan dengan memberikan makanan bagi gelandangan dan ODGJ'
    },
    {
        image: '/kegiatan/natal1.jpeg',
        description: 'Mengadakan acara natal bersama'
    },
    {
        image: '/kegiatan/natal2.jpeg',
        description: 'Mengadakan acara natal bersama'
    },
    {
        image: '/kegiatan/natal3.jpeg',
        description: 'Mengadakan acara natal bersama'
    },
    {
        image: '/kegiatan/paskah1.jpeg',
        description: 'Mengadakan acara paskah bersama'
    },
    {
        image: '/kegiatan/paskah2.jpeg',
        description: 'Mengadakan acara paskah bersama'
    },
    {
        image: '/kegiatan/paskah3.jpeg',
        description: 'Mengadakan acara paskah bersama'
    },
]