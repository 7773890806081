import React, { useState } from 'react';
// import { Card, CardContent } from '@mui/material';
import { motion } from 'framer-motion';
import { SliderData } from './SliderData';
import Modal from './Modal'; // Import the Modal component

const ResponsiveGallery: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false); // Track modal open state

  const fadeAnimationVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.2,
      },
    },
  };

  const openModal = (image: string) => {
    setSelectedImage(image);
    setModalOpen(true); // Open modal
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalOpen(false); // Close modal
  };

  return (
    <div className="mt-24" id='cerita1'>
      <p className="text-2xl font-bold text-center text-black">Cerita</p>

      <div className="text-black p-10 mx-auto">
        <motion.div
          variants={fadeAnimationVariants}
          initial="initial"
          whileInView="animate"
          className="w-full max-w-screen-xl p-5 pb-10 mx-auto mb-10 gap-6 lg:columns-3 columns-1 space-y-6"
        >
          {SliderData.map((slide, index) => (
            <div
              key={index}
              onClick={() => openModal(slide.image)}
              className="cursor-pointer transition-transform transform hover:scale-105 hover:filter hover:brightness-75"
            >
              <img
                src={slide.image}
                alt={slide.description}
                className="w-full h-auto"
              />
            </div>
          ))}
        </motion.div>
      </div>

      {/* Display the Modal and Modal Backdrop */}
      {modalOpen && selectedImage !== null && (
        <>
          <div
            onClick={closeModal}
            className="fixed inset-0 bg-black opacity-70 z-50" // Dark backdrop
          />
          <Modal
            image={selectedImage}
            description={
              SliderData.find((slide) => slide.image === selectedImage)?.description || ''
            }
            closeModal={closeModal}
          />
        </>
      )}
    </div>
  );
};

export default ResponsiveGallery;