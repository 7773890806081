import { Button } from '@mui/material';
import HeroImg from '../images/hero.jpeg'
import KBArrowDown from '../images/keyboard_arrow_down.svg'
import { Link } from 'react-scroll';


const Hero = () => {

    return (
        <div className='relative w-full h-screen overflow-hidden' id='hero'>
            <div className="">
                <img src={HeroImg} alt="hero" className="absolute inset-0 object-cover w-full h-full" />
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-black/70" />
            </div>

            <div className="absolute inset-0 text-color flex flex-col items-center justify-center ">
                <div className="p-5 text-color z-10 lg:ml-[-40rem] text-left">
                    <h2 className="text-5xl font-semibold mb-2">Aku Melihat Engkau</h2>
                    <h2 className="text-2xl font-semibold">Banyak orang MELIHAT namun tidak BERBUAT</h2>
                    <p className="py-5 text-xl tracking-wider">Yayasan Kesejahteraan Anak Aku Melihat Engkau <br /> Medan - Indonesia</p>
                </div>
            </div>

            <div className="absolute flex: 1 items-center justify-center bottom-0 left-0 right-0 mb-1.5">
                <Button>
                    <Link to='content' spy={true} smooth={true} offset={-125} duration={500}>
                        <img src={KBArrowDown} alt="/" />
                    </Link>
                </Button>
            </div>

        </div>
    )
}

export default Hero