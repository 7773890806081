import React from 'react';
import { motion } from 'framer-motion';
import { Close } from '@mui/icons-material';

interface ModalProps {
  image: string;
  description: string;
  closeModal: () => void;
}

const Modal: React.FC<ModalProps> = ({ image, description, closeModal }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 flex items-center mt-24 justify-center z-50"
    >
      <div className="lg:px-16 p-6 rounded-lg shadow-lg lg:max-w-xl max-w-xs modal-content relative">
        <button
          onClick={closeModal}
          className="absolute top-0 right-0 lg:p-2 ml-2 bg-gray-200 rounded-full hover:bg-gray-300"
        >
          <Close />
        </button>
        <img src={image} alt={description} className="w-full h-auto" />
        <p className="text-white mt-4 lg:text-xl">{description}</p>
      </div>
    </motion.div>
  );
};

export default Modal;
